export default {
  // Endpoints
  // loginEndpoint: '/jwt/login',
  // registerEndpoint: '/jwt/register',
  // refreshEndpoint: '/jwt/refresh-token',
  // logoutEndpoint: '/jwt/logout',

  // loginEndpoint: 'http://api.topolingua.com:8080/apifront/token/',
  // registerEndpoint: 'http://api.topolingua.com:8080/apifront/registeruser/',
  // refreshEndpoint: 'http://api.topolingua.com:8080/apifront/token/refresh/',
  // addtextEndpoint: 'http://api.topolingua.com:8080/apifront/textadd/',
  // textaddbythemeEndpoint: 'http://api.topolingua.com:8080/apifront/textaddbytheme/',
  // getUserTextEndpoint: 'http://api.topolingua.com:8080/apifront/usertextview/',
  // getQuizEndpoint: 'http://api.topolingua.com:8080/apifront/quiz/',
  // getUserProfileEndpoint: 'http://api.topolingua.com:8080/apifront/userprofile/',
  // getTopTransactionsEndpoint: 'http://api.topolingua.com:8080/apifront/toptransactions/',
  // getAllTransactionsEndpoint: 'http://api.topolingua.com:8080/apifront/alltransactions/',
  // deleteQuizEndPoint: 'http://api.topolingua.com:8080/apifront/deletetext/',
  // upQIsActiveEndPoint: 'http://api.topolingua.com:8080/apifront/up_q_isactive/',
  // authorofquizEndPoint: 'http://api.topolingua.com:8080/apifront/authorofquiz/',
  // gettextEndPoint: 'http://api.topolingua.com:8080/apifront/gettext/',
  // activateAccountEndPoint: 'http://api.topolingua.com:8080/apifront/activateaccount/',
  // resetPasswordEndPoint: 'http://api.topolingua.com:8080/apifront/passwordreset/',
  // confirmResetedPasswordEndPoint: 'http://api.topolingua.com:8080/apifront/passwordreset/confirm/',
  // getSubscriptionsEndpoint: 'http://api.topolingua.com:8080/apifront/activesubscriptions/',
  // googleAuthEndpoint: 'http://api.topolingua.com:8080/auth/google/',
  // getAssistants: 'http://api.topolingua.com:8080/apifront/getassistants/',
  // getChats: 'http://api.topolingua.com:8080/apifront/getchats/',
  // sendMessage: 'http://api.topolingua.com:8080/apifront/sendmessage/',
  // sendAudio: 'http://api.topolingua.com:8080/apifront/sendaudio/',
  // deleteСhats: 'http://api.topolingua.com:8080/apifront/deletechats/',
  // getUserLanguagesEndpoint: 'http://api.topolingua.com:8080/apifront/getuserlanguages/',
  // saveUserLanguagesEndpoint: 'http://api.topolingua.com:8080/apifront/saveuserlanguages/',
  // isUserLanguagesConfiguredEnpoint: 'http://api.topolingua.com:8080/apifront/isuserlanguagesconfigured/',
  // getNewsHomeEndpoint: 'http://api.topolingua.com:8080/apifront/getnewshome/',
  // getNewsEndpoint: 'http://api.topolingua.com:8080/apifront/getnews/',
  // getOneNewsEndpoint: 'http://api.topolingua.com:8080/apifront/getonenews/',
  // sendTextToTranslateEndpoint: 'http://api.topolingua.com:8080/apifront/transtext/',
  // addPhraseEndpoint: 'http://api.topolingua.com:8080/apifront/addphrase/',
  // getPhrasesEndpoint: 'http://api.topolingua.com:8080/apifront/getphrases/',
  // updatePhraseEndpoint: 'http://api.topolingua.com:8080/apifront/updatephrase/',
  // deletePhraseEndpoint: 'http://api.topolingua.com:8080/apifront/deletephrase/',
  // getUserstatEndpoint: 'http://api.topolingua.com:8080/apifront/getuserstat/',
  // // logoutEndpoint: 'http://api.topolingua.com:8080/apifront/logout/',

  loginEndpoint: 'https://api.topolingua.com/apifront/token/',
  registerEndpoint: 'https://api.topolingua.com/apifront/registeruser/',
  refreshEndpoint: 'https://api.topolingua.com/apifront/token/refresh/',
  addtextEndpoint: 'https://api.topolingua.com/apifront/textadd/',
  textaddbythemeEndpoint: 'https://api.topolingua.com/apifront/textaddbytheme/',
  getUserTextEndpoint: 'https://api.topolingua.com/apifront/usertextview/',
  getQuizEndpoint: 'https://api.topolingua.com/apifront/quiz/',
  getUserProfileEndpoint: 'https://api.topolingua.com/apifront/userprofile/',
  getTopTransactionsEndpoint: 'https://api.topolingua.com/apifront/toptransactions/',
  getAllTransactionsEndpoint: 'https://api.topolingua.com/apifront/alltransactions/',
  deleteQuizEndPoint: 'https://api.topolingua.com/apifront/deletetext/',
  upQIsActiveEndPoint: 'https://api.topolingua.com/apifront/up_q_isactive/',
  authorofquizEndPoint: 'https://api.topolingua.com/apifront/authorofquiz/',
  gettextEndPoint: 'https://api.topolingua.com/apifront/gettext/',
  activateAccountEndPoint: 'https://api.topolingua.com/apifront/activateaccount/',
  resetPasswordEndPoint: 'https://api.topolingua.com/apifront/passwordreset/',
  confirmResetedPasswordEndPoint: 'https://api.topolingua.com/apifront/passwordreset/confirm/',
  getSubscriptionsEndpoint: 'https://api.topolingua.com/apifront/activesubscriptions/',
  googleAuthEndpoint: 'https://api.topolingua.com/auth/google/',
  getAssistants: 'https://api.topolingua.com/apifront/getassistants/',
  getChats: 'https://api.topolingua.com/apifront/getchats/',
  sendMessage: 'https://api.topolingua.com/apifront/sendmessage/',
  sendAudio: 'https://api.topolingua.com/apifront/sendaudio/',
  deleteСhats: 'https://api.topolingua.com/apifront/deletechats/',
  getUserLanguagesEndpoint: 'https://api.topolingua.com/apifront/getuserlanguages/',
  saveUserLanguagesEndpoint: 'https://api.topolingua.com/apifront/saveuserlanguages/',
  isUserLanguagesConfiguredEnpoint: 'https://api.topolingua.com/apifront/isuserlanguagesconfigured/',
  getNewsHomeEndpoint: 'https://api.topolingua.com/apifront/getnewshome/',
  getNewsEndpoint: 'https://api.topolingua.com/apifront/getnews/',
  getOneNewsEndpoint: 'https://api.topolingua.com/apifront/getonenews/',
  sendTextToTranslateEndpoint: 'https://api.topolingua.com/apifront/transtext/',
  addPhraseEndpoint: 'https://api.topolingua.com/apifront/addphrase/',
  getPhrasesEndpoint: 'https://api.topolingua.com/apifront/getphrases/',
  updatePhraseEndpoint: 'https://api.topolingua.com/apifront/updatephrase/',
  deletePhraseEndpoint: 'https://api.topolingua.com/apifront/deletephrase/',
  getUserstatEndpoint: 'https://api.topolingua.com/apifront/getuserstat/',
  // logoutEndpoint: 'https://api.topolingua.com/apifront/logout/',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
