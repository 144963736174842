import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import moment from 'moment'

// deleted, tryed to use for select text
import Vue2TouchEvents from 'vue2-touch-events'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'



// TODO delete in production
// Axios Mock Adapter
// topolingua doesn't use it
// import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// https://www.npmjs.com/package/vue2-touch-events
// disableClick default false. Use touch event only, will not trigger click event. We use mouseup instead
Vue.use(Vue2TouchEvents, {
  disableClick: true,
  // touchClass: '',
  // tapTolerance: 10,
  // touchHoldTolerance: 400,
  // swipeTolerance: 30,
  // longTapTimeInterval: 400,
  // namespace: 'touch'
})

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.filter('formatDate', value => {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY HH:mm')
  }
  return ''
})

// Vue.filter('formatTime', function(value) {
//   if (value) {
//     return moment(String(value)).format('HH:mm')
//   }
// })

Vue.filter('formatTime', value => {
  if (value) {
    return moment(String(value)).format('HH:mm')
  }
  return ''
})

// Vue.filter('formatCentsToEuro', function(value) {
//   if (value) {
//     return (value/100).toFixed(2)
//   }
// })

Vue.filter('formatCentsToEuro', value => {
  if (value) {
    return (value / 100).toFixed(2)
  }
  return 0
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
