import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

function getInterfaceLanguage() {
  const userData = JSON.parse(localStorage.getItem('userData'))
  if (userData) {
    if (userData.interface_language) {
      return userData.interface_language.lang_code ? userData.interface_language.lang_code : 'en'
    }
  }
  return 'en'
}

export default new VueI18n({
  // locale: 'uk-UA',
  locale: getInterfaceLanguage(),
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
})
